<template>
    <div class="d-flex align-items-center flex-row-reverse" style="gap: 20px">
        <ek-dialog
            v-if="getUserRoles() == Admin || getUserRoles() == SuperAdmin"
            ref="addDialog"
            title="إضافة قسم"
            size="md"
            btnText="اضافة قسم"
            @ok="submit()"
            @close="reset"
        >
            <template #body>
                <ValidationObserver ref="subjectForm">
                    <b-form>
                        <div
                            class="d-flex justify-content-between align-items-center w-100"
                        >
                            <h4>حالة قسم اساسي</h4>
                            <b-form-radio-group
                                id="radio-group-2"
                                v-model="chapterDto.isFree"
                                name="radio-sub-component"
                            >
                                <b-form-radio :value="true">مجاني</b-form-radio>
                                <b-form-radio :value="false"
                                    >مدفوع</b-form-radio
                                >
                            </b-form-radio-group>
                        </div>
                        <ek-input-text
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  اسم القسم  مطلوب',
                                },
                            ]"
                            name="name"
                            placeholder=" ادخل اسم القسم "
                            label="  اسم القسم "
                            v-model="chapterDto.name"
                        ></ek-input-text>
                        <ek-input-text
                            name="  ترتيب القسم"
                            label="ترتيب القسم"
                            ref="order"
                            type="number"
                            v-model="chapterDto.order"
                            placeholder=" ادخل الترتيب"
                        ></ek-input-text>
                        <ek-input-select
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  استاذ القسم',
                                },
                            ]"
                            :options="teachersNane"
                            name="classes"
                            v-model="chapterDto.teacherId"
                            placeholder=" ادخل استاذ القسم "
                            label="استاذ القسم"
                            clearable
                            @change="resetFaculty"
                        ></ek-input-select>
                        <!-- <ek-input-select
                            :rules="[
                                {
                                    type: 'required',
                                    message: '  يرجى اختيار الجامعة',
                                },
                            ]"
                            :options="universityList"
                            name="classes"
                            v-model="chapterDto.universityId"
                            placeholder="تابع (الجامعة - كلية - فرع)"
                            label="تابع (الجامعة - كلية - فرع)"
                            clearable
                        ></ek-input-select> -->

                        <b-row no-gutters>
                            <b-col lg="12" md="12">
                                <ek-input-select
                                    :options="unitList.units"
                                    label="تابع للوحدة"
                                    name="تابع للوحدة"
                                    placeholder="تابع للوحدة"
                                    v-model="chapterDto.unitId"
                                    :rules="[
                                        {
                                            type: 'required',
                                            message: 'المادة مطلوبة',
                                        },
                                    ]"
                                >
                                </ek-input-select>
                            </b-col>
                        </b-row>
                    </b-form>
                </ValidationObserver>
            </template>
        </ek-dialog>

        <ek-input-text
            v-model="filterDto.search"
            placeholder="ابحث عن قسم"
        ></ek-input-text>
    </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapGetters } from "vuex";
import { getUserRoles } from "@/app/admin/auth/utils";
import { SuperAdmin, Admin } from "@/router";
export default {
    components: {
        ValidationObserver,
    },
    data: () => ({
        SuperAdmin,
        Admin,
    }),
    computed: {
        ...mapState({
            chapterDto: (state) => state.chapter.chapterDto,
            filterDto: (state) => state.chapter.filterDto,
            url: (state) => state.chapter.url,
            universityList: (state) => state.university.universityList,
            teachersNane: (state) => state.settings.teachersNane,
            unitList: (state) => state.unit.unitList,
        }),
        ...mapGetters(["getUrl"]),
    },
    methods: {
        ...mapActions(["updatechapter", "addFile", "getTeacherName"]),
        getUserRoles,
        submit() {
            this.$refs["subjectForm"].validate().then((success) => {
                if (success) {
                    this.updatechapter({
                        ...this.chapterDto,
                        id: null,
                    }).then((data) => {
                        // this.$store.commit("Add_Courses_Subjects", data);
                        console.log(data);
                        this.$store.dispatch("getchapterList", {
                            universityId: "",
                            facultyId: "",
                            yearId: "",
                            semesterId: "",
                            pageIndex: 1,
                            pageSize: 4,
                        });
                        // window.location.reload();
                    });
                    console.log(getUserRoles(), "test");
                    this.$refs.addDialog.close();
                    this.$store.commit("RESET_SUBJECT");
                    this.$refs.subjectForm.reset();
                }
            });
        },

        resetFaculty() {
            this.chapterDto.universityId = "";
            this.chapterDto.facultyId = "";
        },
        resetDepartment() {
            this.chapterDto.facultyId = "";
            this.chapterDto.departmentId = "";
        },
        reset() {
            this.$refs.subjectForm.reset();
            this.$refs.imgFile.reset();
            this.$store.commit("RESET_SUBJECT");
            Object.assign(this.chapterDto, {
                name: "",
                imageUrl: "",
                subjectId: "",
                isFree: false,
                semesterId: "",
                yearId: "",
                departmentId: "",
                universityId: "",
                facultyId: "",
            });
            this.$refs.addDialog.close();
        },
        uploadSubFile(event) {
            if (event.file) {
                this.addFile({ ...event, folderName: "Profile" });
            }
        },
    },
    created() {
        this.getUserRoles();

        this.getTeacherName();
    },
    beforeDestroy() {
        this.$store.commit("Reset_chapter");
    },
};
</script>

<style>
#color {
    width: 30px !important;
    height: 30px !important;
}
</style>
